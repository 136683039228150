/**
 * @file AppRoutes.js
 * @description Definisce tutte le rotte principali dell'applicazione utilizzando React Router, inclusa la gestione delle pagine protette.
 * Le rotte dell'applicazione vengono gestite qui per centralizzare la logica di navigazione e permettere un facile accesso e aggiornamento.
 * Utilizza il componente `ProtectedRoute` per garantire che solo utenti con ruoli specifici, come "admin", possano accedere a determinate pagine.
 */

// Import delle dipendenze
import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

// Import dei components
import ProtectedRoute from '../components/ProtectedRoute';

// Lazy loading delle pagine
const HomePage = lazy(() => import('../pages/static/HomePage'));
const AboutPage = lazy(() => import('../pages/static/AboutPage'));
const SignupPage = lazy(() => import('../pages/auth/SignupPage'));
const LoginPage = lazy(() => import('../pages/auth/LoginPage'));
const AdminPage = lazy(() => import('../pages/dashboard/AdminPage'));
const AccountPage = lazy(() => import('../pages/dashboard/AccountPage'));

/**
 * Componente che definisce le rotte dell'applicazione.
 * 
 * @component
 * @returns {JSX.Element} Componente che contiene tutte le rotte principali dell'applicazione.
 * Le rotte includono pagine pubbliche come Home, About, Signup e Login, nonché pagine protette come Admin.
 */
const AppRoutes = () => (
   <Suspense fallback={<div>Caricamento...</div>}>
      <Routes>
         <Route path="/" element={<HomePage/>} />
         <Route path="/about" element={<AboutPage/>} />
         <Route path="/signup" element={<SignupPage/>} />
         <Route path="/login" element={<LoginPage/>} />
         <Route path="/admin" element={<ProtectedRoute element={AdminPage} allowedRoles={['admin']}/>} />
         <Route path="/account" element={<ProtectedRoute element={AccountPage} allowedRoles={['user', 'admin']} />} />
      </Routes>
   </Suspense>
);

export default AppRoutes;