/**
 * @file AuthContext.js
 * @description Gestisce lo stato globale dell'autenticazione degli utenti, centralizzando tutte le funzionalità di autenticazione.
 * Questo file offre un contesto React per rendere le informazioni di autenticazione disponibili a qualsiasi componente dell'applicazione che lo richiede. 
 * I componenti come `Sidenav`, `SignupPage`, `LoginPage` utilizzano `useAuth` per accedere allo stato di autenticazione, al token CSRF e alle funzioni di login/logout.
 * Centralizzare la gestione dell'autenticazione in questo contesto consente una maggiore manutenibilità e facilita la gestione dello stato utente in modo coerente.
 */

// Importa le funzioni di React necessarie per creare il contesto di autenticazione e gestire lo stato e gli effetti del componente.
import React, { createContext, useState, useEffect, useContext } from 'react';

// Importa Axios per effettuare richieste HTTP al backend, ad esempio per la verifica dell'autenticazione e la gestione del token CSRF.
import axios from 'axios';

/**
 * @constant {Object} AuthContext - Crea un contesto per l'autenticazione utente.
 * Viene utilizzato per fornire accesso alle informazioni dell'utente e alle funzioni di autenticazione.
 */
const AuthContext = createContext();

/**
 * Hook personalizzato per utilizzare il contesto di autenticazione.
 * Questo hook semplifica l'accesso al contesto di autenticazione per i componenti che ne hanno bisogno.
 * @returns {Object} L'oggetto del contesto di autenticazione.
 */
export const useAuth = () => useContext(AuthContext);

/**
 * Provider del contesto di autenticazione che fornisce lo stato dell'utente, il token CSRF e le funzioni di autenticazione.
 * Questo componente dovrebbe avvolgere il resto dell'app per fornire accesso globale allo stato di autenticazione.
 * 
 * @param {Object} props - Le proprietà passate al provider.
 * @param {React.ReactNode} props.children - I componenti figli che verranno avvolti dal provider.
 * @returns {JSX.Element} Un provider che espone il contesto di autenticazione.
 */
export const AuthProvider = ({ children }) => {
   
   const [user, setUser] = useState(null); // Stato dell'utente attuale (null se non autenticato) 
   const [loading, setLoading] = useState(true); // Stato per gestire il caricamento (utile per evitare operazioni prima che la verifica dell'utente sia completata)
   const [csrfToken, setCsrfToken] = useState(''); // Stato per il token CSRF

   /**
   * Effettua la verifica dell'accesso dell'utente all'avvio e ottiene il token CSRF.
   * Questo effetto viene eseguito al montaggio del componente per inizializzare lo stato di autenticazione.
   * 
   * @function useEffect
   * @memberof AuthProvider
   */
   useEffect(() => {
      const initializeAuth = async () => {
         try {
            // Richiesta per ottenere il token CSRF dal backend
            const csrfResponse = await axios.get(`/api/csrf-token`, { withCredentials: true });
            setCsrfToken(csrfResponse.data.csrfToken);

            // Richiesta per verificare se l'utente è già autenticato
            const response = await axios.get(`/api/verify-token`, {
               withCredentials: true, // Necessario per inviare i cookie
            });

            // Se l'utente è autenticato, aggiorna lo stato con le informazioni dell'utente
            if (response.status === 200 && response.data.loggedIn) {
               setUser({ 
                  id: response.data.userId, 
                  role: response.data.role, 
                  googleId: response.data.googleId || null
               });
            } else {
               setUser(null);
               //console.log("Nessun utente autenticato.");
            }
         } catch (error) {
            // In caso di errore (ad esempio token non valido), imposta l'utente a null
            setUser(null);
            console.log("Errore durante la verifica dell'utente:", error);
         } finally {
            // Una volta completata la verifica, aggiorna lo stato di caricamento
            setLoading(false);
         }
      };
      initializeAuth();
   }, []);
 

   /**
   * Funzione per eseguire il logout dell'utente.
   * Esegue una richiesta al backend per eliminare i cookie di accesso e rimuovere le informazioni dell'utente dal contesto.
   *
   * @function logout
   * @memberof AuthProvider
   */
   const logout = async () => {
      try {
         await axios.post(`/api/logout`, {}, {
         withCredentials: true,
         headers: {
            'CSRF-Token': csrfToken, // Invia il token CSRF
         }
         });
         setUser(null); // Rimuove le informazioni dell'utente dal contesto
      } catch (error) {
         console.error('Errore durante il logout:', error);
      }
   };

   /**
   * Renderizza i componenti figli solo dopo che il caricamento iniziale del contesto 
   * di autenticazione è completato, evitando che i componenti vengano mostrati durante la verifica dello stato utente.
   * 
   * @constant {JSX.Element}
   */
   return (
      <AuthContext.Provider value={{ user, setUser, loading, csrfToken, logout }}>
         {!loading && children}
      </AuthContext.Provider>
   );
};