/**
 * @file App.js
 * @description Componente principale dell'applicazione React. Configura il contesto di autenticazione, il router e include la navbar e le rotte principali dell'app.
 * Centralizza la gestione delle funzionalità globali come l'autenticazione tramite `AuthProvider` e gestisce la navigazione tramite `React Router`.
 */

// Import delle dipendenze
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Import degli stili CSS Globali
import './assets/css/main.css';

// Import dei components (Stili Inclusi nei components)
import Navbar from './components/core/Sidenav';
import Header from './components/core/Header';

// Import del context
import { AuthProvider } from './context/AuthContext';

// Import del routes
import AppRoutes from './routes/AppRoutes';

/**
 * Componente principale dell'applicazione che include il contesto di autenticazione, il router e i componenti principali.
 * 
 * @component
 * @returns {JSX.Element} Restituisce il componente principale dell'applicazione.
 * - Avvolge l'intera applicazione con `AuthProvider` per fornire funzionalità di autenticazione globali.
 * - Utilizza `Router` per gestire la navigazione dell'applicazione.
 * - Include la navbar e tutte le rotte definite nel componente `AppRoutes`.
 */
function App() {
   return (
      <AuthProvider>
         <div class="page-wrapper">
            <Router>
               <aside class="main-sidenav">
                  <Navbar/>
               </aside>          
               <div class="main-wrapper">
                  <Header/>
                  <AppRoutes/>
               </div>
            </Router>
         </div>
      </AuthProvider>
   );
}

export default App;