/**
 * @file LogoutButton.js
 * @description Componente dedicato per il logout dell'utente.
 * Chiama l'endpoint di logout sul server e aggiorna lo stato globale.
 */

import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

/**
 * Componente che mostra un pulsante "Logout" e richiama la funzione "logout" dal contesto.
 */
const LogoutButton = () => {
  const { logout } = useAuth();  // estrai la funzione logout dal contesto
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();              // chiama la funzione dal contesto
    navigate('/login');               // reindirizza dove preferisci (opzionale)
  };

  return (
    <button onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;